// 👇 It's important to not import @valu/react-valu-search directly here but
// only the subpackage `lazy`.  This ensures the main entry bundle won't get
// bloated by RVS.
import { LazyValuSearch, select } from "@findkit/pink-ocelot/lazy";

const loader = new LazyValuSearch({
    // import the file exporting the ValuSearch instance as the default export
    load: () => import("./search-ui"),
});

/**
 * INSTRUCTIONS:
 * This loader.tsx and valu-search.tsx are enough to connect valu search
 * to a button that open search. RVS will open in full screen modal.
 *
 * If you need to open search in partial modal, or customize the search,
 * check the docs in README.
 *
 * There is another commented out setup for outside input at the bottom
 * of this file.
 */

/**
 * Case: Outside button that opens fullscreen modal
 */
// loader.init(() => {
//     const button = select("#open-button", HTMLButtonElement);

//     button.addEventListener(
//         "click",
//         () => {
//             // The activate() method lazy loads the valu-search.js file and
//             // calls .activate() on the ValuSearch instance as well as soon as
//             // possible. On subsequent calls when the module is already loaded it
//             // just activates the VS instance.
//             loader.activate();

//             // Since the lazy loading might take a second we add a class that
//             // visualizes the loading state via CSS if it is not loaded yet.
//             if (!loader.isLoaded()) {
//                 button.classList.add("loading");
//             }
//         },
//         false,
//     );

//     return (vs) => {
//         // Once ready we can remove the loading indicator
//         button.classList.remove("loading");
//         vs.initModal();
//     };
// });

/**
 * Case: Outside input opens fullscreen modal
 */
// loader.init(() => {
//     const input = select(".outside-input", HTMLInputElement);

//     // this binds event listener to input focus event and triggers load
//     // if the input had focus already when this code is run.
//     // this can happen in lazy load
//     loader.loadOnFocus(input);

//     return (vs) => {
//         vs.bindInputAsOpener(input);
//         vs.initModal();
//     };
// });

/**
 * Case: Outside button && outside input that both open fullscreen modal
 */
loader.init(() => {
    const button = select("#Layout1_ctl00_search_Button1", HTMLInputElement);
    const openLink = select(".toggle.searchtoggle", HTMLLinkElement, {
        nullable: true,
    });
    const input = select("#Layout1_ctl00_search_TextBox1", HTMLInputElement);

    // this binds event listener to input focus event and triggers load
    // if the input had focus already when this code is run.
    // this can happen in lazy load
    loader.loadOnFocus(input);

    button.addEventListener(
        "click",
        () => {
            // The activate() method lazy loads the valu-search.js file and
            // calls .activate() on the ValuSearch instance as well as soon as
            // possible. On subsequent calls when the module is already loaded it
            // just activates the VS instance.
            loader.activate();
        },
        false,
    );

    if (openLink) {
        openLink.addEventListener(
            "click",
            () => {
                // The activate() method lazy loads the valu-search.js file and
                // calls .activate() on the ValuSearch instance as well as soon as
                // possible. On subsequent calls when the module is already loaded it
                // just activates the VS instance.
                loader.activate();
            },
            false,
        );
    }

    return (vs) => {
        // Once ready we can remove the loading indicator
        vs.bindInputAsOpener(input);
        vs.initModal();
    };
});
